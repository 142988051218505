<template>
    <div class="index-container">
        <div class="course-content">
            <div class="select-wrapper">
                <div class="select-left">新闻管理</div>
                <div class="select-btn">
                    <el-button type="primary" @click="addNews">新增新闻</el-button>
                </div>
            </div>

            <el-table :data="newsList" border style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}" :cell-style="{fontSize: '12px',color: '#333'}">
                <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                <el-table-column prop="title" label="新闻标题"></el-table-column>
                <el-table-column prop="sort" label="文章排序" width="80" align="center"></el-table-column>
                <el-table-column prop="create_time" label="发布时间" width="200" align="center"></el-table-column>
                <el-table-column label="操作" width="300" align="center">
                    <template slot-scope="scope">
                        <el-button size="small" @click="seeNews(scope.row)">查看</el-button>
                        <el-button size="small" type="primary" @click="editNews(scope.row)">编辑</el-button>
                        <el-button size="small" type="danger" @click="delNews(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pages-right" style="margin-top: 20px;"
                           :current-page.sync="newsPages.currentPageNum"
                           :page-size="newsPages.eachPageNum"
                           :total="newsPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="newsCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                newsList: [],
                newsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getNewsList();
        },
        methods: {
            getNewsList() {
                let params = {
                    page: this.newsPages.currentPageNum,
                    limit: this.newsPages.eachPageNum,
                }
                this.$http.axiosGetBy(this.$api.newsList, params, (res) => {
                    if (res.code === 200) {
                        this.newsList = res.data
                        this.newsPages.total = res.total;
                    }
                })
            },
            newsCurrentChange(val) {
                this.newsPages.currentPageNum = val;
                this.getNewsList();
            },
            addNews() {
                this.$router.push('/admin/newsManage/add')
            },
            seeNews(row) {
                let routeUrl = this.$router.resolve({
                    path: '/qualityCourse/newsCenterDetail',
                    query: {newsId: row.id}
                })
                window.open(routeUrl .href, '_blank')
            },
            editNews(row) {
                this.$router.push({path: '/admin/newsManage/add', query: {newsId: row.id}})
            },
            delNews(row) {
                this.$confirm('确定删除该新闻吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        id: row.id
                    }
                    this.$http.axiosPost(this.$api.newsDel, params, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getNewsList();
                        } else {
                            this.$message.error(res.msg)
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .course-content {
        padding: 20px;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        .select-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
</style>